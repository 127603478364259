<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="渠道id：">
                    <el-input v-model="relation_id" placeholder="请输入渠道id"></el-input>
                </el-form-item>
                <el-form-item label="授权者id：">
                    <el-input v-model="owner" placeholder="请输入授权者"></el-input>
                </el-form-item>
                <el-form-item label="使用者id：">
                    <el-input v-model="borrow" placeholder="请输入使用者"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="status" placeholder="请选择">
                        <el-option
                        v-for="item in type_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form>
        </div>
        <el-table
            v-loading="loading"
            :data="authorizationList"
            border>
            <el-table-column
                fixed
                prop="id"
                label="编号"
                width="100"
            >
            </el-table-column>
            <el-table-column
                fixed
                prop="relation_id"
                label="渠道id"
                width="200"
            >
            </el-table-column>
<el-table-column prop="owner_user_id" label="授权者user_id(user_key)" width="300">
    <template slot-scope="scope">
        {{scope.row.owner_user_id}}({{scope.row.owner_user_key}})
    </template>
</el-table-column>
<el-table-column prop="borrow_user_id" label="使用者user_id(user_key)" width="300">
    <template slot-scope="scope" v-if="scope.row.borrow_user_id != 0">
        {{scope.row.borrow_user_id}}({{scope.row.borrow_user_key}})
    </template>
</el-table-column>
<el-table-column label="创建时间">
    <template slot-scope="scope">
        {{scope.row.create_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="更新时间">
    <template slot-scope="scope">
        {{scope.row.update_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="授权状态" width="100px">
    <template slot-scope="scope">
        {{scope.row.status | type}}
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

</section>
<!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    let common = JSON.parse(window.localStorage.getItem('common'))
    export default {
        name: 'authorization',
        components: {},
        data() {
            return {
                authorizationList: [],
                type_list: [{
                    type: 0,
                    name: '全部'
                }, {
                    type: 1,
                    name: '使用中'
                }, {
                    type: 2,
                    name: '未使用'
                }, {
                    type: 3,
                    name: '已封停'
                }],
                total: 0,
                page: 1,
                pagesize: 10,
                relation_id: '',
                owner: '',
                borrow: '',
                status: '',
                loading: true
            }
        },
        watch: {},
        filters: {
            type(val) {
                let name = ''
                switch (Number(val)) {
                    case 0:
                        name = ''
                        break;
                    case 1:
                        name = '使用中'
                        break;
                    case 2:
                        name = '未使用'
                        break;
                    case 3:
                        name = '已封停'
                        break;
                    default:
                        break;
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getAuthorization()
        },
        methods: {
            close() {
                this.show = false
                this.getAuthorization()
            },
            getAuthorization(page, size) {
                this.loading = true
                api.getAuthorization({
                    relation_id: this.relation_id,
                    owner_user_id: this.owner,
                    borrow_user_id: this.borrow,
                    status: this.status,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.authorizationList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getAuthorization()
            },
            currentChange(val) {
                this.page = val
                this.getAuthorization()
            },
            seach(page, size) {
                this.getAuthorization(page, size)
            }
        }
    }
</script>
<style scoped>
    .tip {
        color: #ff5050;
    }
    
    .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>